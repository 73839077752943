<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined color="#f1f1f1">
        <v-card-title class="orange-bg font-weight-bold white--text">
          Send Kudos
        </v-card-title>
        <v-card-text class="mt-3">
          <!-- Start Send Kudos section -->
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="kudos.communityName"
                    label="Community"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="kudos.from"
                    label="From"
                    :error-messages="fromErrors"
                    @input="$v.kudos.from.$touch()"
                    @blur="$v.kudos.from.$touch()"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="kudos.body"
                    label="Message"
                    :error-messages="bodyErrors"
                    @input="$v.kudos.body.$touch()"
                    @blur="$v.kudos.body.$touch()"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn color="orange-bg white--text" @click="sendKudos">
                Submit
              </v-btn>
            </v-container>
          </v-form>

          <!-- End Send Kudos section -->
        </v-card-text>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  API_CUSTOMERS,
  POST_DATA,
  API_KUDOS
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
import { required } from "vuelidate/lib/validators";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    mgr: new Mgr(),
    userInfo: null,
    communityInfo: {},
    kudos: {
      community: "",
      from: "",
      body: ""
    },
    pageText: "",
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return {
      kudos: {
        from: {
          required
        },
        body: {
          required
        }
      }
    };
  },
  async mounted() {},
  async created() {
    this.userInfo = await this.mgr.getUser();
    this.kudos.from =
      this.userInfo.profile.fullName + " <" + this.userInfo.profile.email + ">";
    await this.getComunityInfo();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },

    async getComunityInfo() {
      this.loading = true;

      let self = this;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.communityInfo = crud.getPopulatedComunityInfo(response.data);
            self.createBreadcrumb();
            self.setInternalBreadcrumbs();

            self.kudos.communityName = self.communityInfo.name;
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Send Kudos" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Send Kudos" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    sendKudos() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.kudos.communityId = this.customerId;

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: `${API_KUDOS}`,
          data: self.kudos
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent =
              "Thank you for sending us some kudos. We appreciate the opportunity to serve you. Your message will be sent to your Account Manager and the Yardnique management team.";
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    }
  },
  computed: {
    fromErrors() {
      const errors = [];
      if (!this.$v.kudos.from.$dirty) return errors;
      !this.$v.kudos.from.required && errors.push("Required.");
      return errors;
    },
    bodyErrors() {
      const errors = [];
      if (!this.$v.kudos.body.$dirty) return errors;
      !this.$v.kudos.body.required && errors.push("Required.");
      return errors;
    }
  }
};
</script>
